import React from 'react';
import { bool, shape, string, func } from 'prop-types';
import { RecommendationsCarouselSnapped } from '@recommendations-fe/carousel-snapped';
import RecommendationsIconFactory from './icon-factory';
import { RecommendationsCardHorizontalTitleFirst } from './recomendations-card';

const ID = 'carousel_above_seller';

const Recommendations = ({
  isPolycard,
  thousandSeparator,
  decimalSeparator,
  featureName,
  runCatchErrorBoundary = () => {},
  ...props
}) => {
  try {
    return (
      <RecommendationsCarouselSnapped
        Card={RecommendationsCardHorizontalTitleFirst}
        contextProps={{
          type: 'list-card',
          thousandSeparator,
          decimalSeparator,
        }}
        cardProps={{
          polyClassName: 'recos-polycard poly-card--mobile',
        }}
        isPolycard={isPolycard}
        IconFactory={RecommendationsIconFactory}
        {...props}
        minItemsToShow={featureName === ID ? 1 : undefined}
        runCatchErrorBoundary={runCatchErrorBoundary}
      />
    );
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

Recommendations.propTypes = {
  isPolycard: bool.isRequired,
  decimalSeparator: string.isRequired,
  thousandSeparator: string.isRequired,
  className: string,
  apiresponse: shape({}),
  featureName: string,
  runCatchErrorBoundary: func,
};

export default Recommendations;
